<template>
    <div class="content-wrap">
        <img src="@/assets/img/resources/403.png" alt="找不到图片">
        <div>抱歉，当前登录用户没有访问该页面的权限</div>
        <el-button type="primary" @click="toLogin">返回首页</el-button>
    </div>
</template>

<script>
    export default {
        name:"NotRoles",
        methods: {
            toLogin(){
                this.$router.push("/");
            }
        }
    }

</script>

<style scoped lang="scss">
    .content-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            width: 302px;
            height: 256px;
        }

        div {
            font-size: 18px;
            color: #666666;
            margin: 60px 0 20px 0;
        }
    }
</style>